import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 

import {config} from '../../components/Common/constant';
import Demand_Your_Home from '../../components/modules/demand_your_home';

import { GET_ARTICLE } from "../../queries/common_use_query";

const StatisticsArea = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('preval-presentation-sold-statistics');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        <div className="pre-wrapper">

        <Header />

        <Demand_Your_Home />

        <NavLink tab_name="Marketing" prevLink={config.preval_people}  nextLink={config.preval_valuation_day} />

        </div>
    </>
}

export default StatisticsArea