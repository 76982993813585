import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import Title_Block from  "../modules/title_block"; 

const DemandYourHome = (props) => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('preval-presentation-sold-statistics');

    useEffect(()=>{
      setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    return <>
      <div className="section-wrapper">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={config.preval_your_home} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} />

            <p className="fig-content font_18">{article_data && HTMLReactParser(article_data.Content)}</p>

            <figure className="digitalDomn">
                { article_data && article_data.Image && <img src={strapi_url+''+article_data.Image.url} alt="" /> }
            </figure>
          </Container>
      </div>
    </>
}

export default DemandYourHome